import { ChangeEvent, forwardRef, KeyboardEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { Input, InputGroup, InputLeftElement, InputRightElement, SystemStyleObject } from '@chakra-ui/react';
import { Size } from '@components/common/size';
import { XMarkIcon } from '@heroicons/react/24/solid';

import AutocompleteBody from './AutocompleteBody';
import { useAutocompleteActions } from './useAutocompleteActions';

export interface Item {
  value: string;
  label: string;
  leftItemIcon?: ReactNode | string;
}

interface AutocompleteProps {
  items: Item[];
  placeholder: string;
  rightIcon?: ReactNode;
  isEditable?: boolean;
  autocompleteBodyClassName?: string;
  currentValue?: Item;
  initialValue?: Item;
  setCurrentValue: (value?: Item) => void;
  preventFilter?: boolean;
  size?: Size;
  sx?: SystemStyleObject;
  className?: string;
  onResetComplete?: () => void;
  shouldReset?: boolean;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
  applySxRightElement?: boolean;
  classNameInput?: string;
}

const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(
  (
    {
      showLeftIcon = true,
      showRightIcon = true,
      items,
      placeholder,
      rightIcon,
      isEditable = true,
      autocompleteBodyClassName,
      sx,
      currentValue,
      setCurrentValue,
      preventFilter = false,
      initialValue,
      className,
      size,
      onResetComplete,
      shouldReset,
      applySxRightElement = true,
      classNameInput
    },
    ref
  ) => {
    const autocompleteRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState<string>(initialValue?.label || '');

    const handleSetCurrentValue = (isOutside: boolean, value?: Item) => {
      setCurrentValue(value);
      if (isOutside && !value) {
        setInputValue('');
      }
    };

    const handleResetComplete = () => {
      setInputValue('');
      onResetComplete?.();
    };

    const { onShowResults, onBlur, onChange, onClick, onKeyDown, activeItem, isVisible, filteredData } =
      useAutocompleteActions(
        items,
        autocompleteRef,
        'autocomplete',
        handleSetCurrentValue,
        handleResetComplete,
        currentValue,
        isEditable,
        shouldReset
      );

    const setNextInputValue = (value: string) => {
      setInputValue(value);
      onChange(value);
    };
    const handleSetInputValue = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
      setNextInputValue(value);
    };

    const handleClearSearchField = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setNextInputValue('');
    };

    return (
      <div ref={autocompleteRef} id="autocomplete" className={className}>
        <InputGroup
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onClick={() => onShowResults(preventFilter ? undefined : currentValue?.label)}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {currentValue?.leftItemIcon && showLeftIcon && (
            <InputLeftElement sx={{ top: size === 'sm' ? '-6px' : '-2px' }}>
              {currentValue?.leftItemIcon}
            </InputLeftElement>
          )}
          <Input
            ref={ref}
            isReadOnly={!isEditable}
            placeholder={placeholder}
            sx={{
              borderRadius: 20,
              bg: 'rentennials.inputBg',
              h: 8,
              fontFamily: 'Roboto',
              fontSize: size === 'sm' ? '12px' : '14px',
              lineHeight: '20px',
              color: 'rentennials.input',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              border: 'unset',
              cursor: 'pointer',
              ...(size === 'sm' && { h: '24px' }),
              ...sx
            }}
            className={classNameInput}
            type="text"
            onChange={handleSetInputValue}
            onKeyDown={onKeyDown}
            value={currentValue?.label || inputValue || ''}
            onBlur={onBlur}
          />
          {rightIcon && showRightIcon && (
            <InputRightElement
              className="flex items-center justify-center"
              sx={applySxRightElement ? { top: size === 'sm' ? -2 : -1 } : undefined}
            >
              {isVisible && isEditable ? (
                <XMarkIcon className="h-4 w-4 text-rentennials-input" onClick={handleClearSearchField} />
              ) : (
                rightIcon
              )}
            </InputRightElement>
          )}
        </InputGroup>
        {isVisible && (
          <AutocompleteBody
            activeItem={activeItem}
            items={preventFilter ? items : filteredData}
            onItemSelect={onClick}
            show={isVisible}
            styles={{ minWidth: `${autocompleteRef.current?.clientWidth}px` }}
            className={autocompleteBodyClassName}
          />
        )}
      </div>
    );
  }
);

export default Autocomplete;
